import { useEffect, useState } from "react"

export default function useMobile() {

  const [ windowSize, setWindowSize ] = useState<number>( window.innerWidth )

  useEffect( () => {
    function handleResize() {
      setWindowSize( window.innerWidth )
    }
    window.addEventListener( `resize`, handleResize )

    return () => { window.removeEventListener( `resize`, handleResize ) }
  })

  return windowSize < 1150
}