import { Instagram, Facebook, Mail } from 'resources/icons'
import AIcon from './AIcon'

export default function IconToolbar() {
  return (
    <div className="flex gap-6 mt-2">
      <AIcon src={Instagram} href={`https://www.instagram.com/spectrababyusa/`} target="_blank" />
      <AIcon src={Facebook} href={`https://www.facebook.com/SpectraBabyUsa/`} target="_blank" />
      <AIcon src={Mail} href={`mailto:customerservice@spectrababyusa.com`} />
    </div>
  )
}