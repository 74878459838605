import InsuranceLookup from './insuranceLookup/InsuranceLookup'
import Footer from 'components/footer'
import SpectraLogo from 'resources/images/Spectra-Website-Header-Logo-Pink.png'

export default function App() {
  return (
    <div className="sm:max-w-sm md:max-w-6xl mx-auto mt-10 sm:p-0 md:px-6 ">
      <img src={SpectraLogo} width={200} className="sm:mx-auto md:m-0 sm:pl-10 md:pl-0" />
      <InsuranceLookup />
      <Footer />
    </div>
  )
}
