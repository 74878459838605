import React from "react"

export default function AButton( props : {href: string, target?: string, className?: string, children: React.ReactNode}) {

  const handleButtonClick = () => {
    if ( props.target ) {
      return window.open( props.href, props.target )
    }

    return window.location.href = props.href
  }

  return (
    <button className={`${props.className ? props.className : `btn btn-secondary`}`} onClick={handleButtonClick}>
      {props.children}
    </button>
  )
}