import PropTypes from 'prop-types'

const SelectInput = ({
  name,
  value,
  onChange,
  type = `text`,
  required = false,
  label,
  className = `input`,
  errorClassName = `select-error`,
  labelClassName = `input-label`,
  errorMessageClassName = `text-error text-xs px-3`,
  children,
  errorMessage = ``,
  id = undefined,
  reference = undefined,
  disabled = false,
  ...rest
}) => {

  // sets error, disabled, or normal className on input
  const inputClassName = ( errorMessage ) ? errorClassName : (
    ( disabled ) ? `input-disabled ${className}` : className
  )

  return (
    <div className={`flex flex-col`}>
      {label && (
        <label htmlFor={id || name} className={labelClassName}>
          {label}
        </label>
      )}

      <select
        id={id || name}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        className={inputClassName}
        required={required}
        ref={reference}
        {...rest}
      >
        {children}
      </select>

      {errorMessage && <p className={errorMessageClassName}>{errorMessage}</p>}
    </div>
  )
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ).isRequired,
  onChange: PropTypes.func,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  errorMessageClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  errorMessage: PropTypes.string,
  asteriskClassName: PropTypes.string,
  id: PropTypes.string,
  reference: PropTypes.any, // tODO: get ref type
  disabled: PropTypes.bool
}

export default SelectInput
