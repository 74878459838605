import { Check, Equis } from "resources/icons"
import { ISupplier } from "types"
import AButton from 'components/AButton'

export default function Supplier( props : ISupplier ) {

  const isAvailableJSX = ( option : string ) => {
    return <img src={option === `0` ? Equis : Check} />
  }

  return (
    <>
      <div className="sm:w-10/12 md:w-full sm:pl-10 md:pl-0 mx-auto mb-4">
        <p className="font-semibold">{props.description}</p>
        <p>{`Phone Number ${props.phone}`}</p>
        <p>{`Email ${props.email}`}</p>
        <p className="flex gap-1">{`Multiple Pump Models`}{isAvailableJSX( props.pump_models )}</p>
        <p className="flex gap-1">{`Upgrade Pumps Available`}{isAvailableJSX( props.upgrades )}</p>
        <p className="flex gap-1">{`Resupply Available`}{isAvailableJSX( props.resupply )}</p>
        <p className="flex gap-1">{`Accessories Available`}{isAvailableJSX( props.accessories )}</p>
        <p className="flex gap-1">{`Lactation Services Available`}{isAvailableJSX( props.lactation_services )}</p>
      </div>
      <div className="flex sm:justify-center md:justify-start mb-4">
        <AButton className="btn btn-primary" target="_blank" href={props.url}>{`Apply Now`}</AButton>
      </div>
    </>
  )
}
