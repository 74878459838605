import SelectInput from 'components/forms/SelectInput'
import React, { useState } from 'react'
import usaStates from 'resources/constants/usaStates'
import { getSuppliers, getPayers } from 'utils/api'
import { IPayer, ISupplier } from 'types'

type onSubmitFn = ( _suppliers : ISupplier[] ) => void

export default function PayersForm( props : {onSubmit : onSubmitFn}) {

  const [ stateSelection, setStateSelection ] = useState<string>( `` )
  const [ payers, setPayers ] = useState<IPayer[]>( [] )
  const [ payerSelection, setPayerSelection ] = useState<string>( `` )

  function submit( event : React.FormEvent<HTMLButtonElement> ) {
    event.preventDefault()

    getSuppliers( stateSelection, payerSelection ).then( data => {
      props.onSubmit( data )
    })
      .catch( error => {
        console.error( error )
      })
  }

  const resetParentPayers = () => { return props.onSubmit( [] ) }

  const handleChange = ( event : React.ChangeEvent<HTMLSelectElement> ) => {
    const { target : { name, value }} = event
    resetParentPayers() // notifies the parent that a change has been made and potential previous data is no longer needed
    if ( name === `state` ) {
      setStateSelection( value )
      getPayers( value ).then( data => {
        setPayers( [ ...data ] )
      })
    } else {
      setPayerSelection( value )
    }
  }

  return (
    <form className="payers-form">
      <h3>{`Find a preferred supplier`}</h3>
      <SelectInput
        name="state"
        inputClassName="input md:max-w-xl bg-white"
        labelClassName="input-label"
        label="Select the state of your insurer"
        value={stateSelection}
        onChange={handleChange}
        required
      >
        <option value="" disabled hidden >{``}</option>
        {usaStates.map( state => {
          return <option key={state.value} value={state.value}>{state.text}</option>
        })}
      </SelectInput>
      <SelectInput
        name="payer"
        inputClassName="input md:max-w-xl bg-white"
        labelClassName="input-label"
        label="Chose your insurance"
        value={payerSelection}
        onChange={handleChange}
        required
      >
        <option value="" disabled hidden >{``}</option>
        {payers.map( payer => {
          return <option key={payer.id} value={payer.id}>{payer.description}</option>
        })}
      </SelectInput>
      <br></br>
      <button className="btn btn-primary" onClick={submit}>{`Submit`}</button>
    </form>
  )
}