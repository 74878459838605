export const getPayers = async ( state : string ) => {
  const response = await fetch( `${process.env.REACT_APP_API_URL}/get-payers`, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`,
      'apiKey': `${process.env.REACT_APP_API_KEY}`
    },
    body: JSON.stringify({
      state: state
    })
  })

  return await response.json()
}

export const getSuppliers = async ( state : string, payer: string | number ) => {
  const response = await fetch( `${process.env.REACT_APP_API_URL}/get-dmes`, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`,
      'APIKEY': `${process.env.REACT_APP_API_KEY}`
    },
    body: JSON.stringify({
      state: state,
      payer: payer
    })
  })

  return await response.json()
}