import Carousel from 'components/carousel'
import Supplier from './Supplier'
import { ISupplier } from 'types'

export default function DMEDisplay( props : {suppliers : ISupplier[]}) {
  if ( !props.suppliers.length ) return <span className="sm:hidden md:inline"><Carousel /></span>

  return <div className="-mt-15 sm:pb-10 md:m-0 md:py-5">{props.suppliers.map( supplier => {
    return <Supplier key={supplier.id} {...supplier} />
  })}</div>
}