import IconToolbar from "./IconToolbar"
import AButton from '../AButton'

export default function Footer() {
  return (
    <footer className="flex flex-wrap-reverse max-w-md-screen sm:justify-center md:justify-end gap-6 mt-4">
      <div className="flex sm:flex-wrap md:flex-nowrap gap-6 sm:w-full justify-center md:w-5/12">
        <AButton href={`https://www.spectrababyusa.com/store/c1/Featured_Products.html`} target="_blank">{`Shop Collection`}</AButton>
        <AButton href={`mailto:customerservice@spectrababyusa.com`}>{`Contact Us`}</AButton>
      </div>
      <IconToolbar />
    </footer>
  )
}