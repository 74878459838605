import { useState } from 'react'
import PayersForm from './PayersForm'
import Carousel from 'components/carousel'
import Suppliers from './Suppliers'
import { ISupplier } from 'types'

export default function InsuranceLookup() {

  const [ suppliers, setSuppliers ] = useState<ISupplier[]>( [] )

  return (
    <>
      <span className="sm:inline md:hidden"><Carousel /></span>
      <div className="bg-gray border-bottom-primary flex sm:flex-wrap md:flex-nowrap">
        <div className="sm:w-full">
          <PayersForm onSubmit={setSuppliers} />
        </div>
        <div className="sm:w-full">
          <Suppliers suppliers={suppliers} />
        </div>
      </div>
    </>
  )
}