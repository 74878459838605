export default function AIcon( props : { src : string, href: string, target?: string }) {
  return (
    <img
      src={props.src} width={37} height={37}
      className="cursor-pointer"
      onClick={() => {
        if ( props.target ) {
          return window.open( props.href, props.target )
        }

        return window.location.href = props.href
      }}
    />
  )
}