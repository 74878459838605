import { useRef, useEffect } from 'react'
import useMobile from 'utils/customHooks'
import Slider from 'react-slick'
import ImageSlide from './ImageSlide'
import { mnb0_mobile, mnb1_mobile, mnb2_mobile, mnb0_desktop, mnb1_desktop, mnb2_desktop } from 'resources/images'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const config = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false
}

export default function Carousel() {

  const sliderRef = useRef<Slider>( null )
  const isMobile = useMobile()

  useEffect( () => {
    sliderRef?.current?.slickPlay()
  }, [] )

  return (
    <div className="image-slide">
      <Slider {...config} ref={sliderRef}>
        <ImageSlide src={isMobile ? mnb0_mobile : mnb0_desktop} isMobile={isMobile} />
        <ImageSlide src={isMobile ? mnb1_mobile : mnb1_desktop} isMobile={isMobile} />
        <ImageSlide src={isMobile ? mnb2_mobile : mnb2_desktop} isMobile={isMobile} />
      </Slider>
    </div>
  )
}